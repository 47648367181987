import { differenceInYears } from 'date-fns';
import * as Yup from 'yup';

import {
  IndianPassportRegex,
  NonIndianPassportRegex,
  OtherCountriesPincodeRegex,
  phoneRegex,
} from '@/utils/regexExpressions';

const personalInfoSchema = Yup.object().shape({
  gender: Yup.string().nullable().optional(),
  maritalStatus: Yup.string().nullable().optional(),
  dob: Yup.date()
    .typeError(`Please enter valid date of birth`)
    .nullable()
    .optional()
    .test(`min_age`, `Age can't be less than 10`, (value) =>
      !value ? true : differenceInYears(new Date(), new Date(value)) >= 10,
    )
    .test(`max_age`, `Age can't be greater than 99`, (value) =>
      !value ? true : differenceInYears(new Date(), new Date(value)) <= 99,
    ),
  emergencyContactName: Yup.string().optional().nullable(),
  emergencyContactNumber: Yup.string()
    .optional()
    .matches(phoneRegex, `Phone No. is not valid`)
    .nullable(),
  emergencyRelationship: Yup.string().optional().nullable(),
  emergencyContactEmail: Yup.string()
    .email(`Enter a valid email`)
    .notRequired()
    .nullable(),
});
const AddressInfoSchema = Yup.object().shape({
  addressLine1: Yup.string().nullable().optional(),
  addressLine2: Yup.string().optional().nullable(),
  street: Yup.string().optional().nullable(),
  country: Yup.string().nullable().optional(),
  state: Yup.string().nullable().optional(),
  city: Yup.string().nullable().optional(),
  pinCode: Yup.string()
    .when(`country`, {
      is: (country: string) => {
        if (country === `India`) {
          return true;
        }
        return false;
      },
      then: (schema) =>
        schema.test(`pinCode`, ` Only 6 Numbers are allowed.`, (value) => {
          if (value) {
            const isaNum = !isNaN(Number(value));
            const hasvalidLength = value?.length == 6;
            if (isaNum && hasvalidLength) {
              return true;
            } else {
              return false;
            }
          }
          return true;
        }),
      otherwise: (schema) =>
        schema
          .max(15, `Pincode must be atmost 15 characters`)
          .matches(OtherCountriesPincodeRegex, `Enter valid pincode`),
    })
    .nullable()
    .optional(),
});
const PassportInfoSchema = Yup.object().shape(
  {
    issueCountryId: Yup.string().optional().nullable(),
    issueStateId: Yup.string().optional().nullable(),
    cityOfBirth: Yup.string().optional().nullable(),
    nationality: Yup.string().nullable().optional(),
    passportNumber: Yup.string()
      .nullable()
      .optional()
      .when(`nationality`, {
        is: (nationality: string) => {
          if (
            nationality?.toString().toLowerCase() === `india` ||
            nationality?.toString().toLowerCase() === `indian`
          ) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema.matches(IndianPassportRegex, `Passport Number is not valid`),
        otherwise: (schema) =>
          schema
            .max(15, `Passport Number must be atmost 15 characters`)
            .matches(NonIndianPassportRegex, `Passport Number is not valid`),
      }),
    issueDate: Yup.date()
      .typeError(`Please enter valid date`)
      .max(new Date(), `Passport Issued Date cannot be a future Date`)
      .nullable()
      .optional(),
    expiryDate: Yup.date()
      .typeError(`Please enter valid date`)
      .test(
        `Expiry Date`,
        `Expiry Date needs to be after Issue Date`,
        (value, ctx) =>
          !value
            ? true
            : ctx.parent.issueDate
            ? ctx.parent.issueDate.getTime() <= value.getTime()
            : true,
      )
      .nullable()
      .optional(),
  },
  [[`issueDate`, `expiryDate`]],
);
const EducationInfoSchema = Yup.object().shape(
  {
    university: Yup.string().nullable().optional(),
    course: Yup.string().nullable().optional(),
    grade: Yup.number()
      .typeError(`Only positive numbers are allowed`)
      .when(`gradeTotal`, {
        is: (gradeTotal: number) => {
          if (gradeTotal !== null && gradeTotal !== undefined) {
            return true;
          }
          return false;
        },
        then: (schema) =>
          schema.max(
            Yup.ref(`gradeTotal`),
            ({ max }) => `Grade should be not be greater than Grade Total`,
          ),
      })
      .min(0, `Minimum grade is 0`)
      .nullable()
      .optional(),
    gradeTotal: Yup.number()
      .typeError(`Only numbers are allowed`)
      .max(1000, `Maximum Grade is 1000`)
      .min(1, `Minimum Grade is 1`)
      .nullable()
      .when(`grade`, {
        is: (grade: number) => {
          if (grade !== null && grade !== undefined) {
            return true;
          }
          return false;
        },
        then: (schema) => schema.required(`Grade Total is required`),
        otherwise: (schema) => schema.nullable().optional(),
      }),
    startDate: Yup.date()
      .typeError(`Please enter valid date`)
      .max(new Date(), `Start Date cannot be a future Date`)
      .nullable()
      .optional(),
    endDate: Yup.date()
      .typeError(`Please enter valid date`)
      .test(`End Date`, `End Date needs to be after Start Date`, (value, ctx) =>
        !value
          ? true
          : ctx.parent.startDate
          ? ctx.parent.startDate.getTime() <= value.getTime()
          : true,
      )
      .nullable()
      .optional(),
    backLogs: Yup.number()
      .typeError(`Only numbers are allowed`)
      .min(0, `Min Backlog should be 0`)
      .max(20, `Max Backlogs limit is 20`)
      .nullable()
      .optional(),
    country: Yup.string().nullable().optional(),
    city: Yup.string().nullable().optional(),
    courseLanguage: Yup.string().nullable().optional(),
  },
  [
    [`startDate`, `endDate`],
    [`grade`, `gradeTotal`],
  ],
);
const WorkExperienceInfoSchema = Yup.object().shape({
  companyName: Yup.string().typeError(`Please enter company name`).optional(),
  employmentType: Yup.string().nullable().optional(),
  designation: Yup.string().nullable().optional(),
  startDate: Yup.date()
    .typeError(`Please enter valid date`)
    .max(new Date(), `Start Date cannot be a future Date`)
    .nullable()
    .optional(),
  isWorkingPresently: Yup.bool().nullable(),
  endDate: Yup.date()
    .typeError(`Please enter valid date`)
    .test(`End Date`, `End Date needs to be after Start Date`, (value, ctx) =>
      !value
        ? true
        : ctx.parent.startDate
        ? ctx.parent.startDate.getTime() <= value.getTime()
        : true,
    )
    .nullable()
    .optional(),
  stream: Yup.string().nullable(),
  totalExperienceInMonths: Yup.string().nullable(),
});

const ExamInfoSchema = Yup.object().shape({
  examType: Yup.string().optional().nullable(),
  totalScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .nullable()
    .optional(),
  readingScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .nullable()
    .optional(),
  writingScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .nullable()
    .optional(),
  listeningScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  speakingScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  literacy: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  conversation: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  comprehension: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  production: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  gmatVerbalScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  greVerbalScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  gmatQuantitativeScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  greQuantScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  gmatWritingScore: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  greWritingTest: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  gmatIntegratedReasoning: Yup.number()
    .typeError(`Only positive integers are allowed`)
    .min(0)
    .optional()
    .nullable(),
  examDate: Yup.date()
    .typeError(`Please enter valid date`)
    .max(new Date(), `Exam Date cannot be a future date`)
    .optional()
    .nullable(),
  gmatExamDate: Yup.date()
    .typeError(`Please enter valid date`)
    .max(new Date(), `Exam Date cannot be a future date`)
    .optional()
    .nullable(),
  greExamDate: Yup.date()
    .typeError(`Please enter valid date`)
    .max(new Date(), `Exam Date cannot be a future date`)
    .optional()
    .nullable(),
});

const LeadInfoScheam = Yup.object().shape({
  leadQuality: Yup.string()
    .required(`Lead Quality is required`)
    .typeError(`Lead Quality is required`),
  followUpDate: Yup.date()
    .typeError(`Please enter valid follow-up date`)
    .nullable()
    .optional(),
  counsellorList: Yup.array()
    .of(Yup.number())
    .min(1, `Please select at least one counsellor`)
    .required(),
  searchByKey: Yup.string()
    .max(50, `Must be 50 characters or less`)
    .matches(/^[a-zA-Z0-9\s]*$/, `Only alphanumberic text allowed`)
    .nullable()
    .optional(),
});

export {
  personalInfoSchema,
  AddressInfoSchema,
  PassportInfoSchema,
  EducationInfoSchema,
  WorkExperienceInfoSchema,
  ExamInfoSchema,
  LeadInfoScheam,
};
