import { isFalsy } from '@leapfinance/frontend-commons';
import * as yup from 'yup';
import { AnyObject } from 'yup/lib/types';
/**
 *@param this Validates the grade entered by the user based on the grade system selected.
 *@this any
 *@returns boolean or throws an error if the grade is invalid.
 */
function validGradeCheck(
  this: any,
  gradeKey: string,
  gradeSystemKey?: string,
  allowNullable?: boolean,
) {
  return this.test(
    `validGradeCheck`,
    ``,
    function (this: yup.TestContext<AnyObject>, value: any) {
      try {
        const gradeSystem = gradeSystemKey
          ? this.parent?.[gradeSystemKey]?.value
          : this.parent?.gradeSystem?.value;
        if (!gradeSystem) return true;
        const grade = this.parent?.[gradeKey];
        const isGradeNullable = isFalsy(grade);
        if (gradeSystem === `CGPA`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 10;
          if (!(allowNullable && isGradeNullable) && !validGrade)
            return this.createError({
              message: `Enter grade between 0 and 10`,
            });
        } else if (gradeSystem === `PERCENTAGE`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 100;
          if (!(allowNullable && isGradeNullable) && !validGrade)
            return this.createError({
              message: `Enter percentage between 0 and 100`,
            });
        } else if (gradeSystem === `ZERO_TO_FOUR`) {
          const validGrade = Number(grade) >= 0 && Number(grade) <= 4;
          if (!(allowNullable && isGradeNullable) && !validGrade)
            return this.createError({
              message: `Enter grade between 0 and 4`,
            });
        }
        return true;
      } catch (error) {
        console.log(error);
        return true;
      }
    },
  );
}

export { validGradeCheck };
