import * as Yup from 'yup';

const visaSchema = Yup.object().shape({
  universityName: Yup.string()
    .nullable()
    .required(`University Name is required`),
  country: Yup.string().nullable(),
  cspVisaCounsellor: Yup.string().nullable(),
  visaSupportGbRequired: Yup.string().nullable(),
  visaStatus: Yup.string().required(`Visa Status is required`),
  cspVisaCounsellorEmail: Yup.string().email().nullable(),
  gbVisaCounsellor: Yup.string().nullable(),
  visaProcessOnHoldReason: Yup.string()
    .nullable()
    .when(`visaStatus`, {
      is: (exists: any) => !!exists && exists === `VISA_PROCESS_ON_HOLD`,
      then: Yup.string()
        .nullable()
        .required(`Visa process on hold reason is required`),
    }),
  missingDocsForVisaFiling: Yup.array()
    .nullable()
    .when(`visaProcessOnHoldReason`, {
      is: (exists: any) => !!exists && exists === `MISSING_DOCUMENTS`,
      then: Yup.array()
        .nullable()
        .required(`Visa missing doc lists is required`),
    }),
  docsToBeRecollectedForVisaFiling: Yup.array()
    .nullable()
    .when(`visaProcessOnHoldReason`, {
      is: (exists: any) => !!exists && exists === `DOCUMENTS_QUALITY_ISSUE`,
      then: Yup.array()
        .nullable()
        .required(`Visa doc quality list is required`),
    }),
  visaApprovedSubStatus: Yup.string()
    .nullable()
    .when(`visaStatus`, {
      is: (exists: any) => !!exists && exists === `VISA_APPROVED`,
      then: Yup.string().nullable().required(`Visa approved type is required`),
    }),
  visaDroppedReason: Yup.string()
    .nullable()
    .when(`visaStatus`, {
      is: (exists: any) => !!exists && exists === `DROP_OUT`,
      then: Yup.string().nullable().required(`Visa Drop reason is required`),
    }),
  visaDroppedExplanation: Yup.string()
    .nullable()
    .when(`visaStatus`, {
      is: (exists: any) => !!exists && exists === `DROP_OUT`,
      then: Yup.string()
        .nullable()
        .required(`Visa Drop explanation is required`),
    }),
  visaDefferedIntake: Yup.string().nullable(),
  visaDefferedIntakeReason: Yup.string().nullable(),
  interviewDate: Yup.date().nullable(),
  visaRequestDate: Yup.date().nullable(),
  visaProcessOnHoldDate: Yup.date().nullable(),
  visaFilingInProcessDate: Yup.date().nullable(),
  visaFiledDate: Yup.date().nullable(),
  visaApprovedDate: Yup.date().nullable(),
  visaRejectedDate: Yup.date().nullable(),
  visaRejectedReason: Yup.string().nullable(),
  visaDroppedDate: Yup.date().nullable(),
  visaDeferredDate: Yup.date().nullable(),
  visaDeferredIntake: Yup.string().nullable(),
  visaDeferredReason: Yup.string().nullable(),
  visaReappliedDate: Yup.date().nullable(),
});

export { visaSchema };
