import { definitions } from 'types/schema';

import { Shape, Subset } from '@/types/common';
import { GetStudentAcademicInfoResponseType } from '@/types/Services';
import { ExamType } from '@/utils/constants';
import Yup from '@/utils/yup/yupInstance';

type AcademicSchema = Exclude<
  Exclude<
    Exclude<
      GetStudentAcademicInfoResponseType[`data`],
      undefined
    >[`academicInfoDetails`],
    undefined
  >[number],
  undefined
>;
export type BachelorsMasterDefaultFormSchemaType = Pick<
  AcademicSchema,
  | `backLogs`
  | `degree`
  | `documents`
  | `grade`
  | `gradeSystem`
  | `specialisation`
  | `graduationYear`
  | `id`
  | `courseType`
> & { hasBacklog?: string };

type TwelfthDiplomaScehma = Pick<
  AcademicSchema,
  | `stream`
  | `grade`
  | `englishGrade`
  | `backLogs`
  | `graduationYear`
  | `course`
  | `gradeSystem`
> & { hasBacklog?: string };
type ExamSchema = Exclude<
  Exclude<definitions[`StudentExamDetailsDto`], undefined>[`examInfoDetails`],
  undefined
>[number];
type TestSchema = {
  englishExam: ExamSchema;
  gre?: ExamSchema;
  gmat?: ExamSchema;
};

type WorkSchema = Exclude<definitions['StudentWorkExperienceDto'], undefined>;
type SingleWorkSchema = Exclude<definitions['WorkExperienceDto'], undefined>;

type Exams = Exclude<ExamSchema[`examType`], undefined>;
export type EnglishExam = Subset<Exams, `IELTS` | `TOEFL` | `PTE` | `DUOLINGO`>;
type AdditionalExam = Subset<Exams, `GMAT` | `GRE`>;

export const optionSchema = Yup.object()
  .shape({
    label: Yup.string(),
    value: Yup.string(),
  })
  .nullable();

const bachelorsMastersDefaultSchema = Yup.object().shape<
  Shape<Exclude<BachelorsMasterDefaultFormSchemaType, undefined>>
>({
  degree: optionSchema.nullable(),
  specialisation: optionSchema.nullable(),
  graduationYear: optionSchema.nullable(),
  gradeSystem: optionSchema.nullable(),
  grade: Yup.string()
    .validGradeCheck(`grade`)
    .decimalPoints(2, `Only 2 decimal points are allowed`)
    .nullable(),
  backLogs: Yup.number()
    .nullable()
    .when(`hasBacklog`, {
      is: `YES`,
      then: Yup.number().min(1),
    }),
  hasBacklog: Yup.string().nullable(),
});

const twelfthDiplomaSchema = Yup.object().shape<Shape<TwelfthDiplomaScehma>>({
  grade: Yup.string()
    .validGradeCheck(`grade`)
    .decimalPoints(2, `Only 2 decimal points are allowed`)
    .nullable(),
  englishGrade: Yup.string()
    .validGradeCheck(`englishGrade`)
    .decimalPoints(2, `Only 2 decimal points are allowed`)
    .nullable(),
  backLogs: Yup.number()
    .nullable()
    .when(`hasBacklog`, {
      is: `YES`,
      then: Yup.number().min(1).positive(`Enter valid number`),
    }),
  stream: Yup.string().nullable(),
  graduationYear: optionSchema.nullable(),
  course: Yup.string().nullable(),
  hasBacklog: Yup.string().nullable(),
  gradeSystem: optionSchema.nullable(),
});

const englishTestSubScoreValidationForStudentProfileOnboarding = (
  value: any | undefined,
  examType: ExamType,
) => {
  if (examType === `TOEFL`) {
    if (typeof value !== `number`) return false;
    if (Number(value) > 0 && Number(value) <= 30) return true;
    return false;
  }

  return undefined;
};

const englishTestSubScoreValidationMessageForStudentProfileOnboarding = (
  examType: ExamType,
) => {
  if (examType === `TOEFL`) return `Enter value between 0 and 30`;
  return undefined;
};

const examSchema = Yup.object().shape<Shape<TestSchema>>({
  englishExam: Yup.object()
    .shape<Shape<ExamSchema>>({
      examType: optionSchema.nullable(),
      totalScore: Yup.number()
        .test(`score-validation`, ``, function (value) {
          if (typeof value !== `number`) return true;
          const examType: EnglishExam =
            this.parent?.examType?.value ?? this?.parent?.examType;
          if (!examType) return true;

          if (examType === `IELTS`) {
            const validGrade = Number(value) > 0 && Number(value) <= 9;
            if (!validGrade)
              return this.createError({
                message: `Please enter between 0 to 9`,
              });
          } else if (examType === `DUOLINGO`) {
            const validGrade = Number(value) >= 10 && Number(value) <= 160;
            if (!validGrade)
              return this.createError({
                message: `Please enter between 10 to 160`,
              });
          } else if (examType === `PTE`) {
            const validGrade = Number(value) >= 10 && Number(value) <= 90;
            if (!validGrade)
              return this.createError({
                message: `Please enter between 10 to 90`,
              });
          } else if (examType === `TOEFL`) {
            const validGrade = Number(value) >= 0 && Number(value) <= 120;
            if (!validGrade)
              return this.createError({
                message: `Please enter between 0 to 120`,
              });
          }
          return true;
        })
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      readingScore: Yup.number()
        .englishTestScore(
          `examType`,
          englishTestSubScoreValidationForStudentProfileOnboarding,
          englishTestSubScoreValidationMessageForStudentProfileOnboarding,
        )
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      writingScore: Yup.number()
        .englishTestScore(
          `examType`,
          englishTestSubScoreValidationForStudentProfileOnboarding,
          englishTestSubScoreValidationMessageForStudentProfileOnboarding,
        )
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      listeningScore: Yup.number()
        .englishTestScore(
          `examType`,
          englishTestSubScoreValidationForStudentProfileOnboarding,
          englishTestSubScoreValidationMessageForStudentProfileOnboarding,
        )
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      speakingScore: Yup.number()
        .englishTestScore(
          `examType`,
          englishTestSubScoreValidationForStudentProfileOnboarding,
          englishTestSubScoreValidationMessageForStudentProfileOnboarding,
        )
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      literacy: Yup.number()
        .englishTestScore(`examType`)
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      conversation: Yup.number()
        .englishTestScore(`examType`)
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      comprehension: Yup.number()
        .englishTestScore(`examType`)
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
      production: Yup.number()
        .englishTestScore(`examType`)
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
    })
    .nullable(),
  gre: Yup.object()
    .shape<Shape<ExamSchema>>({
      examType: Yup.string()
        .transform((currentValue) => currentValue?.value ?? currentValue)
        .nullable(),
      totalScore: Yup.number()
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
    })
    .nullable(),
  gmat: Yup.object()
    .shape<Shape<ExamSchema>>({
      examType: Yup.string()
        .transform((currentValue) => currentValue?.value ?? currentValue)
        .nullable(),
      totalScore: Yup.number()
        .decimalPoints(2, `Only 2 decimal points are allowed`)
        .nullable(),
    })
    .nullable(),
});

const singleWorkSchema = Yup.object().shape<Shape<SingleWorkSchema>>({
  id: Yup.number().nullable(),
  companyName: Yup.string().nullable(),
  designation: Yup.string().nullable(),
  isWorkingPresently: Yup.string().nullable(),
  totalExperienceInMonths: Yup.number()
    .wholeNumber(`Only whole numbers are allowed`)
    .nullable(),
});

const workSchema = Yup.object().shape<Shape<WorkSchema>>({
  totalWorkExperienceInMonths: Yup.number()
    .wholeNumber(`Only whole numbers are allowed`)
    .nullable(),
  workExperiences: Yup.array().of(
    Yup.object().shape<Shape<SingleWorkSchema>>({
      id: Yup.number().nullable(),
      companyName: Yup.string().nullable(),
      designation: Yup.string().nullable(),
      isWorkingPresently: Yup.string().nullable(),
      totalExperienceInMonths: Yup.number()
        .wholeNumber(`Only whole numbers are allowed`)
        .nullable(),
    }),
  ),
});

export {
  bachelorsMastersDefaultSchema,
  examSchema,
  workSchema,
  twelfthDiplomaSchema,
  singleWorkSchema,
  type WorkSchema,
  type TwelfthDiplomaScehma,
  type ExamSchema,
  type TestSchema,
  // type BachelorsMasterDefaultFormSchemaType,
};
