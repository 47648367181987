import { type TestContext } from 'yup';
import { type AnyObject } from 'yup/lib/types';

import { EnglishExam } from '@/validations/studentProfileonBoarding.scehma';

function wholeNumber(this: any, message?: string) {
  return this.test(
    `wholeNumber`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined` || value === null) return true;
      //check for posetive integer
      const wholeNumber = Number.isInteger(value) && value >= 0;
      if (!wholeNumber) {
        return this.createError({
          message: message ?? `${this.path} must be a whole number`,
        });
      } else {
        return true;
      }
    },
  );
}

function decimal(this: any, message?: string) {
  return this.test(
    `decimal`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;
      //check for posetive integer
      const isDecimal = value >= 0;
      if (!isDecimal) {
        return this.createError({
          message: message ?? `${this.path} must be a decimal`,
        });
      } else {
        return true;
      }
    },
  );
}

function englishTestScore(
  this: any,
  examKey: string,
  isValid: (
    value: any | undefined,
    examType: EnglishExam,
  ) => boolean | undefined,
  message?: (examType: EnglishExam) => string | undefined,
) {
  return this.test(
    `englishTestScore`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value !== `number`) return true;

      const examType: EnglishExam =
        this.parent?.[examKey]?.value ?? this.parent?.[examKey];

      if (!examType) return true;

      if (examType === `IELTS`) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) > 0 && Number(value) <= 9);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 0 to 9`,
          });
      } else if (examType === `DUOLINGO`) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 10 && Number(value) <= 160);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 10 to 160`,
          });
      } else if (examType === `PTE`) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 10 && Number(value) <= 90);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 10 to 90`,
          });
      } else if (examType === `TOEFL`) {
        const validGrade =
          isValid?.(value, examType) ??
          (Number(value) >= 0 && Number(value) <= 120);
        if (!validGrade)
          return this.createError({
            message: message?.(examType) ?? `Please enter between 0 to 120`,
          });
      }
      return true;
    },
  );
}

function decimalPoints(this: any, points: number, message?: string) {
  return this.test(
    `decimal-points`,
    ``,
    function (this: TestContext<AnyObject>, value: number) {
      if (typeof value === `undefined`) return true;
      const decimalSeperated = String(value).split(`.`);

      if (
        decimalSeperated &&
        Array.isArray(decimalSeperated) &&
        decimalSeperated.length == 2
      ) {
        const decimalPoints = decimalSeperated?.[1];
        if (decimalPoints && String(decimalPoints).length > points) {
          return this.createError({
            message: message ?? `${this.path} only ${points} are allowed`,
          });
        }
      } else {
        return true;
      }

      return true;
    },
  );
}

export { wholeNumber, decimal, englishTestScore, decimalPoints };
