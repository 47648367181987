import { store } from '@/app/store';
import { studentApi } from '@/services/student';
import { Shape } from '@/types/common';
import { definitions } from '@/types/schema';
import Yup from '@/utils/yup/yupInstance';

export type StudentPreferencesDto = definitions[`StudentPreferencesDto`];

export type ShortlistProfileObject = Pick<
  StudentPreferencesDto,
  | `studentId`
  | `highestQualificationInfo`
  | `workExperienceInfo`
  | `gapInStudiesInfo`
  | `studentExamInfo`
> & { [k in StudentProfileHelperKeys]: string };

type GapInStudies = Exclude<
  ShortlistProfileObject[`gapInStudiesInfo`],
  undefined
>;

type HighestQualification = Exclude<
  ShortlistProfileObject[`highestQualificationInfo`],
  undefined
>;

type Workexperience = Exclude<
  ShortlistProfileObject[`workExperienceInfo`],
  undefined
>;

type StundetExam = Exclude<
  ShortlistProfileObject[`studentExamInfo`],
  undefined
>;

export enum StudentProfileHelperKeys {
  IS_ENGLISH_EXAM_GIVEN = `isEnglishExamGiven`,
  HAS_WORK_EX = `hasWorkEx`,
  HAS_GAP = `hasGap`,
}

export enum StudentProfileDocKeys {
  IELTS_SCORE_CARD = `IELTS_SCORE_CARD`,
  RESUME = `RESUME`,
  GAP_DOCUMENT = `GAP`,
  TWELFTH_MARK_SHEET = `TWELFTH_MARK_SHEET`,
  DIPLOMA_MARK_SHEET = `DIPLOMA_MARK_SHEET`,
  PHD_MARK_SHEET = `PHD_MARK_SHEET`,
  UG_CONSOLIDATED_MARK_SHEET = `UG_CONSOLIDATED_MARK_SHEET`,
  PG_CONSOLIDATED_MARK_SHEET = `PG_CONSOLIDATED_MARK_SHEET`,
  TENTH_MARK_SHEET = `TENTH_MARK_SHEET`,
  PGD_MARK_SHEET = `PGD_MARK_SHEET`,
  TOEFL_SCORE_CARD = `TOEFL_SCORE_CARD`,
  PTE_SCORE_CARD = `PTE_SCORE_CARD`,
  DUOLINGO_SCORE_CARD = `DUOLINGO_SCORE_CARD`,
  WORK_EX = `WORK_EX`,
}

export const examDocumentMapping = {
  IELTS: StudentProfileDocKeys.IELTS_SCORE_CARD,
  TOEFL: StudentProfileDocKeys.TOEFL_SCORE_CARD,
  PTE: StudentProfileDocKeys.PTE_SCORE_CARD,
  DUOLINGO: StudentProfileDocKeys.DUOLINGO_SCORE_CARD,
};

const qualificationDocMapping = {
  PHD: StudentProfileDocKeys.PHD_MARK_SHEET,
  MASTERS_DEGREE: StudentProfileDocKeys.PG_CONSOLIDATED_MARK_SHEET,
  BACHELORS_DEGREE: StudentProfileDocKeys.UG_CONSOLIDATED_MARK_SHEET,
  PG_DIPLOMA: StudentProfileDocKeys.PGD_MARK_SHEET,
  DIPLOMA: StudentProfileDocKeys.DIPLOMA_MARK_SHEET,
  TWELFTH: StudentProfileDocKeys.TWELFTH_MARK_SHEET,
  TENTH: StudentProfileDocKeys.TENTH_MARK_SHEET,
};

function hasNullUndefinedValues(
  arr: {
    stream?: string | null | undefined;
    totalExperienceInMonths?: number | null | undefined;
    companyName?: string | null | undefined;
  }[],
): boolean {
  for (const obj of arr) {
    if (
      obj.stream === null ||
      obj.stream === undefined ||
      obj.totalExperienceInMonths === null ||
      obj.totalExperienceInMonths === undefined ||
      obj.companyName === null ||
      obj.companyName === undefined
    ) {
      return true;
    }
  }
  return false;
}

const trimValues = (value: string) => {
  return value.trim();
};

const ShortlistPreferenceSchema = Yup.object().shape({
  preferredCountryGroup: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable()
    .required(`Preferred Country group is required`),
  studentDetails: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(`Please select student to request shortlist`),
  degree: Yup.array().min(1).of(Yup.string()).required(),
  tuitionFeesMax: Yup.number().nullable().optional(),
  preferredIntake: Yup.array()
    .min(1)
    .of(Yup.string())
    .required(`Please select at least one intake`),
  expectedCourseCount: Yup.string().nullable().optional(),
  remarks: Yup.string().nullable().optional(),
  universityName: Yup.object()
    .shape({
      collegeId: Yup.number(),
      collegeName: Yup.string(),
      meta: Yup.object().shape({
        countryId: Yup.number(),
        countryName: Yup.string(),
      }),
    })
    .nullable(),
  preferredUniversities: Yup.array()
    .of(
      Yup.object().shape({
        collegeId: Yup.number(),
        collegeName: Yup.string(),
        meta: Yup.object().shape({
          countryId: Yup.number(),
          countryName: Yup.string(),
        }),
      }),
    )
    .nullable()
    .optional(),
  courseName: Yup.string().nullable().optional(),
  preferredCourses: Yup.array()
    .of(
      Yup.object().shape({
        streamId: Yup.number(),
        streamName: Yup.string(),
        streamType: Yup.string(),
      }),
    )
    .min(1)
    .nullable()
    .required(),
});

const StudentProfileSchema = Yup.object().shape<
  Shape<
    ShortlistProfileObject & { englishExam: any; highestQualification: any }
  >
>({
  studentId: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .nullable()
    .required(`Please select student to request shortlist`),
  gapInStudiesInfo: Yup.object().shape<Shape<GapInStudies>>({
    gapInYears: Yup.number()
      .wholeNumber(`Only whole number allowed`)
      .nullable(),
    totalEducationInYears: Yup.number()
      .wholeNumber(`Only whole number allowed`)
      .nullable(),
  }),
  highestQualification: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .test(`qualification-doc-score-check`, ``, function (value, context) {
      const { value: qualification } = value;
      if (!qualification || qualification === null) return true;
      const documentKey =
        qualificationDocMapping[
          qualification as keyof typeof qualificationDocMapping
        ];

      if (documentKey) {
        const crosspondingDocuments = context.parent[documentKey];
        const { backLogs, course, grade } =
          context.parent[`highestQualificationInfo`];
        const scoreCheck = ![`TENTH`, `TWELFTH`].includes(qualification)
          ? (typeof backLogs === `number` ? String(backLogs) : backLogs) &&
            course
          : true;

        const noDocumnets =
          !crosspondingDocuments ||
          (Array.isArray(crosspondingDocuments) &&
            crosspondingDocuments.length === 0);
        const noValue = !(scoreCheck && grade);

        if (noDocumnets && noValue) {
          return this.createError({
            message: `Enter values or Upload document`,
          });
        }
      } else {
        const { backLogs, course, grade } =
          context.parent[`highestQualificationInfo`];

        if (!(String(backLogs) || course || String(grade))) {
          return this.createError({
            message: `All values are required`,
          });
        }
      }
      return true;
    })
    .required(`Highest Qualification is required`)
    .nullable(),
  highestQualificationInfo: Yup.object().shape<Shape<HighestQualification>>({
    backLogs: Yup.number().wholeNumber(`Only whole number allowed`).nullable(),
    grade: Yup.string().nullable(),
    course: Yup.string().nullable(),
  }),
  englishExam: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .test(`english-exam-doc-check`, ``, function (value, context) {
      const isEnglishExamGiven =
        context.parent[StudentProfileHelperKeys.IS_ENGLISH_EXAM_GIVEN];
      if (isEnglishExamGiven === `NO`) return true;
      const { value: examType } = value;
      const createError = this.createError;
      if (!examType || examType === null) return true;
      const documentKey =
        examDocumentMapping[examType as keyof typeof examDocumentMapping];

      if (documentKey) {
        const crosspondingDocuments = context.parent[documentKey];
        const { totalScore } = this.parent[`studentExamInfo`];
        const noDOcuments =
          !crosspondingDocuments ||
          (Array.isArray(crosspondingDocuments) &&
            crosspondingDocuments.length === 0);
        const noValue = totalScore !== 0 && !totalScore;
        if (noDOcuments && noValue) {
          return createError({
            message: `Enter values or Upload document`,
          });
        }
      } else {
        const { totalScore } = this.parent[`studentExamInfo`];
        if (totalScore !== 0 && !totalScore) {
          return createError({
            message: `All values are required`,
          });
        }
      }
      return true;
    })
    .nullable()
    .required(),
  studentExamInfo: Yup.object()
    .shape<Shape<StundetExam>>({
      totalScore: Yup.number().decimal(`Only decimals allowed`).nullable(),
    })
    .nullable(),
  workExperienceInfo: Yup.array().of(
    Yup.object()
      .shape<Shape<Workexperience[number]>>({
        companyName: Yup.string().nullable(),
        stream: Yup.string().nullable(),
        totalExperienceInMonths: Yup.number()
          .wholeNumber(`Only whole number allowed`)
          .nullable(),
      })
      .nullable(),
  ),
  [StudentProfileHelperKeys.IS_ENGLISH_EXAM_GIVEN]: Yup.string(),
  [StudentProfileHelperKeys.HAS_WORK_EX]: Yup.string()
    .test(`work-ex-doc-check`, ``, function (value, context) {
      if (!value || value === `NO`) return true;
      const workExperienceData = context.parent?.workExperienceInfo;
      const workExDocs = context.parent[`WORK_EX`];

      if (Array.isArray(workExDocs) && workExDocs.length > 0) return true;
      else if (
        !workExperienceData ||
        (Array.isArray(workExperienceData) && workExperienceData.length === 0)
      ) {
        return this.createError({
          message: `Either Work Ex information or Document is required`,
        });
      } else if (
        Array.isArray(workExperienceData) &&
        workExperienceData.length > 0
      ) {
        const workExWithMissingInfo = hasNullUndefinedValues(
          workExperienceData ?? [],
        );

        if (workExWithMissingInfo) {
          return this.createError({
            message: `Enter all values or Upload a document`,
          });
        }
      }
      return {} as any;
    })
    .nullable(),
  [StudentProfileHelperKeys.HAS_GAP]: Yup.string().test(
    `gap-doc-data-check`,
    ``,
    function (value, context) {
      const years = context.parent?.gapInStudiesInfo?.gapInYears;
      const totalEducationInYears =
        context.parent?.gapInStudiesInfo?.totalEducationInYears;
      const isGapDocUploaded =
        context.parent[StudentProfileDocKeys.GAP_DOCUMENT];
      const tempTotal =
        typeof totalEducationInYears === `number`
          ? String(totalEducationInYears)
          : totalEducationInYears;
      if (value === `YES` && !years && !tempTotal && !isGapDocUploaded) {
        return this.createError({
          message: `Enter values or upload gap Document`,
        });
      } else if (
        value === `YES` &&
        (!years || !tempTotal) &&
        !isGapDocUploaded
      ) {
        let error;
        if (!years) {
          error = `Number of years is also required`;
        } else if (!tempTotal) {
          error = `Total Education years is also required`;
        }
        return this.createError({ message: error });
      } else if (value === `NO` && !tempTotal) {
        return this.createError({
          message: `Total Education years is required`,
        });
      }
      return true;
    },
  ),
});

const ShortlistRequestSchemaForExistingStudent = Yup.object().shape({
  studentId: Yup.object()
    .shape({
      id: Yup.number(),
      label: Yup.string(),
    })
    .required(),
  degree: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .nullable()
    .optional(),
  remarks: Yup.string().nullable().optional(),
  preferredCountryGroup: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable()
    .optional(),
});

const ShortlistRequestSchemaForNewStudent = Yup.object().shape({
  degree: Yup.array()
    .of(
      Yup.object().shape({
        value: Yup.string(),
        label: Yup.string(),
      }),
    )
    .nullable()
    .optional(),
  remarks: Yup.string().nullable().optional(),
  preferredCountryGroup: Yup.object()
    .shape({
      value: Yup.string(),
      label: Yup.string(),
    })
    .nullable()
    .optional(),
  firstName: Yup.string()
    .required(`Given Name is required`)
    .max(50, `Max 50 characters only`)
    .transform(trimValues),
  lastName: Yup.string()
    .required(`Last Name is required`)
    .max(50, `Max 50 characters only`),
  contactNumber: Yup.string()
    .required(`Mobile Number is required`)
    .test(
      `checkUniqueMobileNumber`,
      `This number is already registered.`,
      async function (item, context) {
        if (
          item &&
          item.length === 10 &&
          context.parent.countryCode?.value === `+91`
        ) {
          const result: any = await store.dispatch(
            studentApi.endpoints.checkDuplicate.initiate({
              mobileNumber: context.parent.countryCode?.value + item,
            }),
          );
          const { data } = result;
          if (data && data.success && data.data?.error) {
            const { error } = data.data;
            return this.createError({ message: error });
          } else {
            return true;
          }
        }
        return true;
      },
    )
    .test(
      `indianMobileFormatCheck`,
      `Enter a valid mobile number`,
      async function (item, context) {
        if (context.parent.countryCode?.value === `+91` && item) {
          return /^[2-9]\d{9}$/.test(item);
        }
        return true;
      },
    ),
  countryCode: Yup.object()
    .shape({ label: Yup.string(), value: Yup.string() })
    .required(),
});

export {
  ShortlistPreferenceSchema,
  StudentProfileSchema,
  ShortlistRequestSchemaForExistingStudent,
  ShortlistRequestSchemaForNewStudent,
};
